import ReactGA from "react-ga4";

// 測定ID（環境変数などで管理するのが望ましい）
const GA_MEASUREMENT_ID = "G-LP917RSM6Z"; // あなたの測定IDに変更

/**
 * Google Analyticsを初期化
 */
export const initGA = (): void => {
  ReactGA.initialize(GA_MEASUREMENT_ID);
};

/**
 * ページビューを送信
 * @param path - ページのパス（デフォルトは `window.location.pathname`）
 */
export const logPageView = (path: string = window.location.pathname): void => {
  ReactGA.send({ hitType: "pageview", page: path });
};

/**
 * イベントを送信
 * @param category - イベントのカテゴリ（例: "Button"）
 * @param action - イベントのアクション（例: "Click"）
 * @param label - イベントのラベル（オプション）
 */
export const logEvent = (
  category: string,
  action: string,
  label?: string
): void => {
  ReactGA.event({ category, action, label });
};
