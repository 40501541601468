import WebGL from "../contents/webGL"; // WebGL コンポーネントをインポート
import styles from "./Hero.module.scss";
import React from "react";
import animeLogo from "../../svg/animeLogo.svg";

const Hero = () => {
  return (
    <div className="webGl" style={{ position: "relative", width: "98vw" }}>
      <WebGL />
      {/* ↓ マウス追従する要素を削除 */}
      {/* <div
        className={styles.cursor}
        style={{
          transform: `translate(${position.x}px, ${position.y}px)`,
        }}
      /> */}
      <div className={styles.hero}>
        <p className={styles.p1}>願いは叶う！！</p>
        <p className={styles.p2}>小さな事からはじめよう。</p>
        <div id={styles.animeLogo}>
          <img src={animeLogo} alt="Logo" />
        </div>
      </div>
    </div>
  );
};

export default Hero;
